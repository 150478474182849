<template>
    <div class="home-box">
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">用户提现管理</span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->
      <div class="top-query">
        <div class="top-query-left">
          <div class="demo-input-suffix">
            联系人名称：
            <el-input placeholder="请输入联系人名称" prefix-icon="el-icon-search" v-model="username" clearable></el-input>
          </div>
          <div class="demo-input-suffix">
            审核状态：
            <el-select v-model="isshenhe" placeholder="请选择" clearable >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            </div>
            <div class="demo-input-suffix">
            账户类型：
            <el-select v-model="type" placeholder="请选择" clearable >
              <el-option
                v-for="item in options2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            </div>
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          <!-- <el-button @click="add" type="success" icon="el-icon-plus">新建</el-button> -->
        </div>
  
        <div class="top-query-right"></div>
      </div>
  
      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="id" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="is_level" label="身份" min-width="120">
            <template slot-scope="scope">
              <span>
                {{ options3.find(item => item.value==scope.row.is_level)?.label }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="username" label="联系人" min-width="100">
          </el-table-column>
          <el-table-column prop="code" label="提现账号" min-width="200">
          </el-table-column>
          <el-table-column prop="money" label="提现金额" min-width="120">
          </el-table-column>
          <el-table-column prop="cost" label="手续费" min-width="120">
          </el-table-column>
          <el-table-column prop="actual_money" label="到账金额" min-width="120">
          </el-table-column>
          <el-table-column prop="type" label="账户类型" min-width="120">
            <template slot-scope="scope">
              <span>
                {{ options2.find(item => item.value==scope.row.type).label }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="bankname" label="账户信息" min-width="120">
          </el-table-column>
          <el-table-column prop="isshenhe" label="审核状态" min-width="120">
            <template slot-scope="scope">
              <el-tag :type="scope.row.isshenhe == 0 ? 'info' : 
                            scope.row.isshenhe == 1 ? 'success' : 
                            scope.row.isshenhe == 2 ? 'danger' : 
                            ''">
                {{ options1.find(item => item.value==scope.row.isshenhe).label }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" min-width="120">
          </el-table-column>
          <el-table-column prop="createdate" label="提现时间" min-width="120">
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" label="操作" min-width="240">
            <template slot-scope="scope">
              <el-button type="primary" v-if="scope.row.isshenhe == 0" plain size="small" @click="process(scope.row.id)"
                >审核</el-button
              >
              <el-button type="primary" plain size="small" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-popconfirm title="确定删除吗？" @confirm="handleDelete(scope.row)">
                <el-button slot='reference' type="danger" plain size="small">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->
      
      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->

      <!-- dialog -->
      <el-dialog
        title="提现审核"
        :visible.sync="dialogFormVisible"
        @close="dialogFormClose('form')"
      >
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          v-loading="detailLoading"
          label-width="120"
        >
          <el-form-item label="审核" prop="isshenhe">
            <el-radio-group v-model="form.isshenhe" style="width: 90%">
              <el-radio :label="1">通过</el-radio>
              <el-radio :label="2">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="描述" prop="remark">
            <el-input
              v-model="form.remark"
              placeholder="备注"
              style="width: 90%"
            ></el-input>
          </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer center">
          <el-button @click="dialogFormClose('form')">取 消</el-button>
          <el-button v-if="dialogType != 'detail'" type="primary" @click="dialogFormSubmit('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  
  export default({
    name: "partnerCashOut",
    data() {
      return {
        username: "",
        tableData: {},
        options1: [{
            value: '0',
            label: '待审核'
        }, {
            value: '1',
            label: '审核通过'
        }, {
            value: '2',
            label: '审核被拒'
        }],
        isshenhe: '',
        options2: [{
            value: 'yhk',
            label: '银行卡'
        }, {
            value: 'zfb',
            label: '支付宝'
        }],
        options3: [{
            value: 1,
            label: '合伙人'
        }, {
            value: 2,
            label: '推广员'
        }],
        type: '',
        total: 100,
        loading: false,
        page: 1,

        dialogFormVisible: false,
        detailLoading: false,
        dialogType: '',
        form: {
          id: '',
          isshenhe: 1,
          remark: "",
        },
        rules: {
          isshenhe: [
            { required: true, message: '请选择审核状态', trigger: 'blur' }
          ]
        },
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      let user = JSON.parse(localStorage.user);
      this.token = user.token;

      this.getAgentCashOutList()
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      search() {
        console.log('查询');
        let data = {
          username: this.username,
          isshenhe: this.isshenhe,
          type: this.type,
          page: this.page
        }
        this.getAgentCashOutList(data)
      },
      add() {
        console.log('新增');
      },
      handleEdit() {
        console.log('编辑');
        this.$message.warning('暂不支持修改')
      },
      handleDelete() {
        console.log('删除');
        this.$message.warning('暂不支持删除')
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.page = val
        this.search()
      },
      getAgentCashOutList(data) {
        this.loading = true
        this.$api.agentCashOutList({...data}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      process(id) {
        console.log('审核');
        this.form.id = id
        this.dialogFormVisible = true
      },
      // 弹窗关闭逻辑
      dialogFormClose(formName) {
        this.$refs[formName].resetFields()
        this.dialogFormVisible = false      // 关闭弹窗
      },
      // 审核提交
      dialogFormSubmit(formName) {
        this.detailLoading = true
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            this.$api
              .shenheCashOut(this.form)
              .then((res) => {
                if (res.code == "success") {
                  this.getAgentCashOutList();
                  this.$message.success(res.msg);
                } else {
                  this.$message.error(res.msg);
                  this.loading = false;
                }
                this.detailLoading = false
              })
              .catch((err) => {
                this.$message.error(err.msg);
                this.loading = false;
              });
            this.dialogFormVisible = false;
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  </style>
  